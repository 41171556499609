import React from "react";
import '../Global.css'
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Header } from "semantic-ui-react";

const DigitalMarketing = () => {
    return (
        <>
            <Head />
            <div className="hero-section-digi">
                <div className="sec-one-div-one">
                    <p>Digital Marketing</p>
                </div>
            </div>

            <Container text>
                <Header as="h3" style={{ fontSize: "2.3em", textAlign: 'center', marginTop: '20px' }}>
                    Digital Marketing
                </Header>
                <p style={{ fontSize: '1.2em' }}>
                    Our digital marketing company formulates creative marketing strategies that help you
                    reach out to the right people with the right message at right time through a right channel.
                    Even if you have a modest budget for your online marketing campaign, we can suggest which
                    service your brand needs most to keep it alive & kicking. Our experts can allocate your
                    marketing budget intelligently across various digital marketing services depending on your
                    business size, its revenue goals & target niche and measure ROI. We always use sophisticated
                    and latest digital marketing tools to provide you effective solutions within stipulated time frame.
                </p>
                <br />
            </Container>
            <Footer />
        </>
    )
};
export default DigitalMarketing;
