import React from "react";
import '../Global.css'
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Header } from 'semantic-ui-react'

const MobileDev = () => {
  return (
    <>
      <Head />
      <div className="hero-section-mob">
        <div className="sec-one-div-one">
          <p>Mobile Devlopment</p>
        </div>
      </div>

      <div style={{ backgroundColor: '#f0f2f5' }}>
        <br />
        <Container text>
          <div>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Mobile App Development
            </Header>
          </div>
          <p>
            Whatever your mobile idea is, we will work with you to
            refine it and ensure that the final outcome meets your business goals.
          </p>
          <p>
            Mobile Apps have become integral tools in managing our lives, staying
            connected and running our businesses. Our expert team of mobile app
            developers deliver smarter and friendlier ways for clients and their
            customers to engage with technology, creating exceptional user experiences
            consistent across technologies. We deliver the best digital products for
            both iOS (iPhone and iPad) and Android (phone and tablet).
          </p>

          <Header as="h3">Android Development</Header>
          <p>Establish a presence on the Google app store with Android app development assistance.</p>

          <Header as="h3">iOS Development</Header>
          <p>
            We know exactly how to engineer innovative iOS apps that leave
            remarkable business footprints on consumer's mindfor companies large and small.
          </p>

          <Header as="h3"> Responsive Design</Header>
          <p style={{ marginBottom: '60px' }}>
            Ensure a consistent, mobile-friendly experience across
            screen sizes with responsive mobile app development and design.
          </p>

          <Header as="h2">Cross-Platform Development</Header>
          <p>
            Interact with your custom software solutions across digital habitats.
            Our qualified software programmers will help you build an app that’s
            “good to go” for all devices and operating systems.
          </p>

          <Header as="h2">Proof of Concept Artwork</Header>
          <p>
            Visualize your customized mobile app solutions and grant
            decisionmakers of your business a chance to approve the model.
          </p>

          <Header as="h2">Quality Assurance Testing</Header>
          <p>
            Guarantee the value of your new, customized mobile app with
            quality assurance testing performed by our quality assurance experts.
          </p>
        </Container>
        <br />
      </div>
      <Footer />
    </>
  )
};
export default MobileDev;
