import React from "react";
import '../Global.css'
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Header, Segment } from 'semantic-ui-react';

const Cloud = () => {
    return (
        <>
            <Head />
            <div className="hero-section-cloud">
                <div className="sec-one-div-one">
                    <p>Cloud Solutions</p>
                </div>
            </div>

            <Segment style={{ padding: "2em em", backgroundColor: '#f0f2f5' }} vertical>
                <Container text>
                    <Header as="h3" style={{ fontSize: "2.3em", textAlign: 'center', marginTop: '20px' }}>
                        Cloud Solutions
                    </Header>
                    <p style={{ fontSize: '1.3em', marginBottom: '30px' }}>
                        A centralized cloud workspace enables your team to share and
                        collaborate instantly and easily.Our in-house team of cloud
                        developers will design, build, test, and deploy your cloud-based
                        project for the unbeatable infrastructure your startup or enterprise needs.
                    </p>

                    <Header as="h3"><i className="circle icon" />Microsoft Azure</Header>
                    <p style={{ fontSize: '1.2em' }}>
                        Manage your business applications through Microsoft’s cloud computing platform.
                        Our in-house experts can help maintain, upgrade, and migrate your business
                        solutions to the Microsoft Azure cloud.
                    </p>

                    <Header as="h3"><i className="circle icon" />AWS (Amazon Web Services)</Header>
                    <p style={{ fontSize: '1.2em' }}>
                        Take advantage of the cloud development services AWS can provide you.
                        Our team uses the comprehensive technology for cloud computing services
                        designed to help with the computing, storage, and analytic aspects of your business.
                    </p>
                </Container>
            </Segment>
            <Footer />
        </>
    )
};
export default Cloud;
