import React from "react";
import '../Global.css'
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Header, Segment } from 'semantic-ui-react';

const HardwareNetworking = () => {
    return (
        <>
            <Head />
            <div className="hero-section-hard">
                <div className="sec-one-div-one">
                    <p>Hardware / Software Integration</p>
                </div>
            </div>

            <Segment style={{ backgroundColor: '#f0f2f5', margin: '0 0 0 0' }}>
                <Container text>
                    <Header as="h3" style={{ fontSize: "2.3em", textAlign: 'center', marginTop: '20px' }}>
                        Hardware/Software Integration
                    </Header>
                    <p style={{ fontSize: '1.2em' }}>
                        Whether you need software that interfaces with a single hardware device or multiple devices,
                        we can build it for you.  We can create mobile, tablet, web or desktop-based applications
                        that read and process data from devices connected over Internet or Bluetooth.<br /><br />

                        Some of the examples of the devices that we have integrated for our clients
                        include Bluetooth Barcode Scanners, temperature probes, temperature sensors,
                        telematic devices.
                    </p>
                </Container>
            </Segment>
            <Footer />
        </>
    )
};
export default HardwareNetworking;
