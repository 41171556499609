import React from "react";
import '../Global.css'
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Header, Segment, Divider } from 'semantic-ui-react';


const WebDev = () => {
    return (
        <>
            <Head />
            <div className="hero-section-web">
                <div className="sec-one-div-one">
                    <p>Web Development</p>
                </div>
            </div>

            <Segment style={{ padding: "2em 0em", backgroundColor: '#f0f2f5' }} vertical>
                <Container text>
                    <Header as="h3" style={{ fontSize: "2em" }}>
                        Web Development Services
                    </Header>
                    <p style={{ fontSize: "1.33em" }}>
                        Digicom Software Solutions provides highly
                        professional Custom Web Design and Web Development services.
                    </p>
                    <p>
                        We can help you establish and grow your presence online by creating
                        a professionally designed websitethat is custom built for your specific
                        needs. We believe that a good website can result in gaining
                        a competitive advantage and enhanced revenue for our clients.
                        Our team focuses on elegant code, clean design, and flawless execution
                        for the best user experience possible on all platforms.
                    </p>

                    <Divider
                        as="h4"
                        className="header"
                        horizontal
                        style={{ margin: "3em 0em", textTransform: "uppercase" }}
                    >-
                    </Divider>

                    <Header as="h3" style={{ fontSize: "1.3em" }}>
                        Our web development services include:
                    </Header>
                    <ol>
                        <li>CUSTOM WEBSITE AND WEB APPLICATION DEVELOPMENT</li>
                        <li>UI/UX WEB DESIGN</li>
                        <li>LONG-TERM SUPPORT AND MAINTENANCE</li>
                        <li> API DEVELOPMENT AND INTEGRATION</li>
                        <li>ECOMMERCE SOLUTIONS</li>
                        <li>INTEGRATION AND CUSTOMIZATION OF CMS</li>
                    </ol>
                </Container>
            </Segment>
            <Footer />
        </>
    )
};
export default WebDev;
