import _ from 'lodash'
import React, { Component } from 'react'
import { Container, Header, Image, Menu, Visibility } from 'semantic-ui-react'
import Head from '../Header/Header'
import Footer from '../Footer/Footer'
import logo from '../Images/digi2.png'

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '4em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}

const fixedMenuStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}

const overlayStyle = {
  float: 'left',
  margin: '0em 3em 1em 0em',
}


export default class AboutUs extends Component {
  state = {
    menuFixed: false,
    overlayFixed: false,
  }

  handleOverlayRef = (c) => {
    const { overlayRect } = this.state
    if (!overlayRect) {
      this.setState({ overlayRect: _.pick(c.getBoundingClientRect(), 'height', 'width') })
    }
  }

  stickOverlay = () => this.setState({ overlayFixed: true })
  stickTopMenu = () => this.setState({ menuFixed: true })
  unStickOverlay = () => this.setState({ overlayFixed: false })
  unStickTopMenu = () => this.setState({ menuFixed: false })

  render() {
    const { menuFixed, overlayFixed, overlayRect } = this.state

    return (
      <div>
        <Head />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container text style={{ marginTop: '2em' }}>
          <Header as='h1'>Digicom Software Solutions</Header>
        </Container>
        <br />
        <br />
      
        <Container text>
          <p>
            Digicom Software Solutions is a software development company
            dedicated to creating the best technology-based solutions
            for our clients. We deliver software (desktop and mobile)
            solutions and provide technology management, support and expertise.
          </p>
          <Visibility
            offset={80}
            once={false}
            onTopPassed={this.stickOverlay}
            onTopVisible={this.unStickOverlay}
            style={overlayFixed ? { ...overlayStyle, ...overlayRect } : {}}
          />
          <p>
            We recognize that in our digital age, technology is in
            a constant state of evolution.  Our team creates digital
            transformation solutions for clients that caters to their
            business goals and meets the challenges of the dynamic
            digital landscape.
          </p>
          <p>
            Whether you are modernizing an established business or
            changing the world with your startup, we are here to
            get you from concept to product in the least amount
            of time.
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          
        </Container>
        <Footer />
      </div>
    )
  }
}
