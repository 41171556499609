import React from "react";
import {
  List,
  Grid,
  Header,
  Segment,
  Container,
  Divider,
  Image,
} from "semantic-ui-react";
import logo from '../Images/digi2.png'


const Footer = () => {
  return (
    <>
      <Segment
        inverted
        vertical
        style={{ margin: "0em 0em 0em", padding: "5em 0em" }}
      >
        <Container textAlign="center">
          <Grid divided inverted stackable>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="App Development" />
              <List link inverted>
                <List.Item as="a">Mobile App Development</List.Item>
                <List.Item as="a">Web Development</List.Item>
                <List.Item as="a">Android App Development</List.Item>
                <List.Item as="a">Ios App Development</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Contact Us" />
              <List link inverted>
                <List.Item as="a"><i class="phone volume icon"></i> +91 731 4084999</List.Item>
                <List.Item as="a"><i className="envelope icon"></i> digicomswsln@gmail.com</List.Item>

              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Office" />
              <List link inverted>
                <List.Item as="a">Indore</List.Item>
                <List.Item as="a">408, Tirupati Business Center, Near Geeta Bhawan ibus Stop, 452001</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={6}>
              <Header inverted as="h4" content="Follow Us" /><br />
              <List.Item as="a"><i className="facebook icon" style={{ fontSize: '30px' }} /></List.Item>
              <List.Item as="a"><i className="twitter icon" style={{ fontSize: '30px' }} /></List.Item>
              <List.Item as="a"><i className="linkedin in icon" style={{ fontSize: '30px' }} /></List.Item>
            </Grid.Column>
          </Grid>
          <br />

          <Divider />
          <Image centered size="mini" src={logo} />
          <List horizontal inverted divided link size="small">
            <List.Item as="a" href="#">
              Site Map
            </List.Item>
            <List.Item as="a" href="#">
              Terms and Conditions
            </List.Item>
            <List.Item as="a" href="#">
              Privacy Policy
            </List.Item>
          </List>
        </Container>
      </Segment>
    </>
  );
};
export default Footer;
