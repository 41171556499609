import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Header/Header.css";
import logo from '../Images/digilogo3.png'
import { Container } from "semantic-ui-react";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <nav className="ui massive  menu" style={{ marginBottom: "0px" }}>
        <div className="logo2">
          <img src={logo} alt="logo" />
        </div>
        <Container>
          <div
            className="nav-links2"
            style={{ transform: open ? "translateX(0px)" : "" }}
          >
            <Link
              to="/"
              style={{ color: "#2656a0" }}
              className="header item h1"
            >
              HOME
            </Link>
            <Link
              to="/AboutUs"
              style={{ color: "#2656a0" }}
              className="header item h1"
            >
              ABOUT US
            </Link>
            <Link
              to="/Contact-us"
              style={{ color: "#2656a0" }}
              className="header item h1"
            >
              CONTACT US
            </Link>

            <div className="right menu"></div>
          </div>
        </Container>
        <i
          onClick={() => setOpen(!open)}
          style={{ color: "#2e56a0", float: "right" }}
          className="fas fa-bars bar"
        />
      </nav>
    </>
  );
};
export default Header;
