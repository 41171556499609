import React, { useState } from "react";
import '../Global.css'
import {
  Form,
  TextArea,
  Button,
  Header,
  Container,
} from "semantic-ui-react";
import Footer from "../Footer/Footer";
import Head from "../Header/Header";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const ContactUs = () => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [mobile, setMobile] = useState("");

  //==========================================================//
  const handleChangeEmail = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
  };

  //==================================================================//
  async function handleSubmit() {
    let item = { firstName, lastName, email, mobile, text };

    let result = await fetch("http://localhost:8000/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });
    result = await result.json();
    console.log("result", result);
  }

  //==========================================================//

  return (
    <div>
      <Head />
      <div className="hero-section">
        <div className="sec-one-div-one">
          <p>Contact Us</p>
        </div>
      </div>

      <Container style={{ width: '45%' }}>
        <div className="ui container">
          <Header
            style={{
              paddingTop: "15px",
              fontSize: "1.6rem",
              lineHeight: "2.4210460186rem",
              color: "#2e56a0",
              marginBottom: '20px'
            }}
          >
            We would love to hear from you.
          </Header>
        </div>

        <div>
          <Form size="large" onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                required
                label="First name"
                placeholder="First name"
                name="fname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Last name"
                placeholder="Last name"
                name="lname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group widths="2">
              <Form.Input
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                fluid
                label="Email"
                placeholder="test@example.com"
                name="email"
                value={email}
                onChange={handleChangeEmail}
              />

              <Form.Field width={8}>
                <label>Mobile</label>
                <PhoneInput
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  name="mobile"
                  value={mobile}
                  onChange={setMobile}
                />
              </Form.Field>
            </Form.Group>

            <TextArea
              required
              placeholder="Message"
              name="info"
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{ minHeight: 150 }}
            />
            <Button style={{ margin: "30px 0 30px 0" }} primary>
              Get In Touch
            </Button>
          </Form>
        </div>

      </Container>
      <Footer />
    </div>
  );
};
export default ContactUs;
