import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AboutUs from "./Assets/AboutUs";
import ContactUs from "./Assets/ContactUs";
import HomePage from "./HomePage/HomePage";
import ApiDev from "./Pages/ApiDev";
import Cloud from "./Pages/Cloud";
import DigitalMarketing from "./Pages/DigitalMarketing";
import HardwareNetworking from "./Pages/HardwareNetworking";
import MobileDev from "./Pages/MobileDev";
import WebDev from "./Pages/WebDev";

const App = () => {
  return (
    <Router>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/AboutUs" component={AboutUs} />
      <Route exact path="/Contact-us" component={ContactUs} />
      <Route exact path="/Mobile-Devlopment" component={MobileDev} />
      <Route exact path="/Web-Devlopment" component={WebDev} />
      <Route exact path="/Cloud-Devlopment" component={Cloud} />
      <Route exact path="/Api-Devlopment" component={ApiDev} />
      <Route exact path="/Hardware-Software" component={HardwareNetworking} />
      <Route exact path="/Digital-Marketing" component={DigitalMarketing} />
    </Router>
  );
};
export default App;
