import React from "react";
import '../Global.css'
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Header, Segment } from 'semantic-ui-react'

const ApiDev = () => {
  return (
    <>
      <Head />
      <div className="hero-section-api">
        <div className="sec-one-div-one">
          <p>REST API Development</p>
        </div>
      </div>

      <Segment style={{ backgroundColor: '#f0f2f5', padding: "2em 0em" }} vertical>
        <Container text>
          <div style={{ textAlign: 'center' }}>
            <Header as="h3" style={{ fontSize: "2.0em", marginBottom: '20px' }}>
              REST API Development and Integration
            </Header>

            <p style={{ fontSize: "1.3em" }}>
              Whether you start with a single web app or you
              launch with web, iOS and Android apps, you need
              Application Programming Interfaces (APIs) to allow
              each client app to communicate with your backend application.
            </p>

            <p style={{ fontSize: "1.3em" }}>
              We can build REST APIs to be used by the web and mobile applications of your company.
              We can also build and release well documented public APIs to let third-party developers
              integrate with your public APIs to build custom software solutions. <br /><br />

              We also provide
              custom third-party web API integration services for small and enterprise businesses
              to connect with website and applications.
            </p>
          </div>
        </Container>
      </Segment>


      <Footer />
    </>
  );
};
export default ApiDev;
