import React from "react";
import "./HomePage.css";
import Head from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Button,
  Image,
  Segment,
  Grid,
  Header,
} from "semantic-ui-react";
import fake from "../Images/Hero1.jpg";
import digital from "../Images/Social.png"
import hd from "../Images/hd.jpg"
import cloud from '../Images/cloud5.jpg'
import mobile from '../Images/Mobile.jpg'
import web from '../Images/web.png'
import api from '../Images/api.jpg'
import { Link } from "react-router-dom";


const HomePage = () => {

  const click = () => {
    window.location.href = '/Contact-us';
  }

  return (
    <div>
      <Head />
      <div className="section">
        <div className="sec-one-div-one">
          <p>
            Let us develop the software you need<br />
            while you focus on your business goals.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default HomePage;
